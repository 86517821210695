// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCYJa9ZlsazdfsPu93Y8nSsfDoEeUw4hmw",
  authDomain: "idfcbk2.firebaseapp.com",
  databaseURL: "https://idfcbk2-default-rtdb.firebaseio.com",
  projectId: "idfcbk2",
  storageBucket: "idfcbk2.appspot.com",
  messagingSenderId: "664912048003",
  appId: "1:664912048003:web:1c2899abc167b0e49050b6",
  measurementId: "G-95ZR5TRGQZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };